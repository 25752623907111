@import '../variables.scss';

.custom-datepicker {
  display: inline-block;
  color: $CDark;
  -webkit-tap-highlight-color: $CTransparent;

  & > * {
    -webkit-tap-highlight-color: $CTransparent;
  }

  @media (max-width: 480px) {
    min-width: 285px;
  }

  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--outside-month) + .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range:not(.react-datepicker__day--outside-month) + .react-datepicker__day--in-range {
    position: relative;

    &::after {
      position: absolute;
      top: 0;
      right: 100%;
      bottom: 0;
      width: 18px;
      margin-right: -7px;
      // background-color: $CGreen;
      content: '';
    }
  }

  .react-datepicker {
    // min-width: 100%;
    padding: 12px;
    color: $CDark;
    font-family: inherit;
    background: $CWhite;
    border: unset;
    // border: 0.5px solid #e2e8f0;
    border-radius: 4px;

    @media (max-width: 480px) {
      padding: 0 8px;
    }

    &__month-wrapper {
      display: flex;
      flex-wrap: nowrap;
    }

    &__week {
      display: flex;
    }

    &__month {
      margin: 0;

      &-container {
        width: 100%;
      }
    }

    &__header {
      min-height: 32px;
      margin-bottom: 4px;
      padding: 0;
      background-color: $CTransparent;
      border-bottom-color: $CTransparent;

      .arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        padding: 0;
        color: $CDark;
        transition: color 0.3s ease-in-out;

        &.--small {
          width: 16px;
          height: 16px;
        }

        svg {
          stroke: currentColor;
        }

        &:hover {
          color: rgba($CDark, 0.4);
        }
      }
    }

    &__current-month {
      margin-bottom: 25px;
    }

    &__day-name,
    &__day {
      flex-shrink: 0;
      width: 32px;
      margin: 0px;
      color: $CDark;
      height: 32px;
      font-size: 15px;
      line-height: 32px;
      font-family: inherit;
      letter-spacing: 0.5px;
      margin: 2px;

      @media (max-width: 480px) {
        max-width: 28px;
      }
    }

    &__day-name {
      color: $CGrey600;
      text-transform: capitalize;
    }

    &__day {
      border-radius: 4px;
      font-size: 14px;
      transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

      @media (max-width: 480px) {
        height: 28px;
        font-size: 14px;
        line-height: 28px;
      }

      &:hover {
        color: $CWhite !important;
        background-color: rgba($CBlue, 0.5);
      }
      
      &:focus {
        outline: none;
      }


      &--in-selecting-range,
      &--in-range {
        background-color: $CBlue700;
      }

      &--disabled,
      &--outside-month {
        pointer-events: none;
        color: $CGrey500;
          background-color: $CTransparent;
        cursor: initial !important;
      }
      &--selected {
        font-weight: bold;
        color: $CWhite !important;
        background-color: $CBlue700;
      }

      &--keyboard-selected {
        background-color: $CWhite !important;
      }
      
      &--today {
        font-weight: bold;
        background-color: $CGrey100;
        color: $CBlack !important;
      }
    }

    &-range {
      .react-datepicker__day {
        &--keyboard-selected,
        &--selected {
          font-weight: bold;
          color: $CWhite !important;
          background-color: $CBlue700;
        }
      }
    }
  }
}
