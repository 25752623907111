@font-face {
  font-weight: 400;
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  src:
    url('roboto-regular-webfont.woff2') format('woff2'),
    url('roboto-regular-webfont.woff') format('woff');
}

@font-face {
  font-weight: 500;
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  src:
    url('roboto-medium-webfont.woff2') format('woff2'),
    url('roboto-medium-webfont.woff') format('woff');
}

@font-face {
  font-weight: 700;
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  src:
    url('roboto-bold-webfont.woff2') format('woff2'),
    url('roboto-bold-webfont.woff') format('woff');
}
