.Toastify {
  &__toast-container {
    z-index: 99999;
    width: auto;
    max-width: 412px;
    padding: 0;
  }

  &__toast-body {
    padding: 0;
  }

  &__toast {
    padding: 0px;
    color: $CBlack;
    background-color: $CWhite;
    border-radius: 8px;
    box-shadow: 0px 10px 50px 0 rgba($CBlack, 0.12);
  }

  &__progress-bar {
    height: 2px;
    background-color: darken($CBlack, 5%);
  }

  &__close-button {
    position: absolute;
    right: 4px;
    top: 4px;
    color: currentColor;
    padding: 6px;

    svg {
      width: 14px;
      height: 16px;
    }
  }
}
