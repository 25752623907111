#remotevideo,
#localvideo {
  video {
    width: 100%;
    height: 100%;
  }
}

#localvideo {
  &.--hidden {
    display: none;
  }
  
  &.--video-h {
    video {
      visibility: hidden;
    }
  }
}
