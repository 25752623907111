@keyframes scale {
  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(-1);
  }
}

@keyframes rotateX {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(720deg);
  }
}

@keyframes circle-default {
  to {
    stroke-dashoffset: 0;
  }
}

.a-rotate-y {
  animation: rotateX 2s linear backwards infinite;
}
