.form-wrapper.--rel {
  position: relative
}

.form-label.--fluid {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 10;
  transition: transform 0.1s linear, top 0.1s linear;

  & > * {
    transition: font-size 0.1s linear, color 0.1s linear;
  }

  &.--active {
    transform: translateY(0%);
    top: 0;
  }
}
