html {
  box-sizing: border-box;
  font-family: $FFamilyMain, sans-serif;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body,
.app,
.app-main,
#root {
  height: 100%;
}

.h-100 {
  height: 100% !important;
}

.w-100 {
  width: 100% !important;
}

body {
  width: 100%;
  margin: 0;
  overflow: hidden;
  // overflow-x: hidden;
  // overflow-y: auto;
  color: $CBlack;
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: 0.1px;
  background-color: $CWhite;

  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 1.3;
    letter-spacing: 0;
  }

  &.noscroll {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul,
li {
  margin: 0;
  padding: 0;
}

svg {
  user-select: none;
}

button,
input,
[role='button'],
textarea {
  font-family: inherit;
  background-color: rgba(0, 0, 0, 0);
  border: unset;

  &:focus {
    outline: unset;
  }
}

button {
  padding: 0;
}

input,
textarea {
  color: inherit;
  font-weight: normal;
  font-size: $FSizeTN;
  font-family: inherit;
  line-height: $FLHTN;
  letter-spacing: $FSpacingMain;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px $CTransparentL inset !important;
}

input::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

[role='button'],
button,
a {
  cursor: pointer;
}

a {
  text-decoration: unset;
}

img,
svg {
  display: block;
  flex-shrink: 0;
}

img {
  max-width: 100%;
}

.d-flex {
  display: flex;
}

.--error {
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: $CRed;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $CRed;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $CRed;
  }

  &::placeholder {
    /* Most modern browsers support this now. */
    color: $CRed;
  }
}

[type='search'] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield;
}

textarea {
  width: 100%;
  min-height: 125px;
  max-height: 300px;
  border: none;
  resize: none !important;
}

.text-elipsed {
  // width: 100%;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

label {
  display: block;
  width: 100%;
  cursor: pointer;
}

.pos-a-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gradient-bg {
  background: linear-gradient(90deg, #00A5EE, #14D7D1);
}

svg {
  #progressBar {
    stroke: $CBlue;
  }
  #progressBarUnder {
    stroke: $CWhite;
  }
  
  &.progress-tr {
    #progressBar {
      stroke: $CWhite;
    }
    #progressBarUnder {
      stroke: $CTransparent;
    }
  }
}

abbr {
  text-decoration: none
}