$scrollbarThumbColor: $CGrey150;
$scrollbarThumbHoverColor: $CGrey200;
$scrollbarThumbActiveColor: $CGrey200;
$scrollbarTrackColor: $CTransparent;



.app {
  * {

    scrollbar-color: #14D7D1 white;
    scrollbar-width: auto;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
    }

    &::-webkit-scrollbar-corner {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: $CGradient;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background: $scrollbarTrackColor;
    }
  }
}
