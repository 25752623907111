$size-0: 0;
$size-h1: 2px;
$size-1: 4px;
$size-2: 8px;
$size-3: 12px;
$size-4: 16px;
$size-5: 20px;
$size-6: 24px;
$size-7: 28px;
$size-8: 32px;
$size-9: 36px;
$size-10: 10px;
$size-30: 30px;
$size-20: 20px;
$size-40: 40px;
$size-50: 50px;
$size-60: 60px;

$sizes: (
  0: $size-0,
  1: $size-1,
  h1: $size-h1,
  2: $size-2,
  3: $size-3,
  4: $size-4,
  5: $size-5,
  6: $size-6,
  7: $size-7,
  8: $size-8,
  9: $size-9,
  10: $size-10,
  20: $size-20,
  30: $size-30,
  40: $size-40,
  50: $size-50,
  60: $size-60,
  n1: -$size-1,
  n10: -$size-10,
  n2: -$size-2,
  n3: -$size-3,
  n4: -$size-4,
  n5: -$size-5,
  a: auto,
);

$variants: (
  t: top,
  b: bottom,
  l: left,
  r: right,
);

$targets: (
  m: margin,
  p: padding,
);

@each $targetIndex, $targetValue in $targets {
  @each $sizeIndex, $sizeValue in $sizes {
    @each $variantIndex, $variantValue in $variants {
      .#{$targetIndex}#{$variantIndex}-#{$sizeIndex} {
        #{$targetValue}-#{$variantValue}: $sizeValue !important;
      }
    }

    .#{$targetIndex}-#{$sizeIndex} {
      #{$targetValue}: $sizeValue !important;
    }

    .#{$targetIndex}a-#{$sizeIndex} {
      #{$targetValue}: $sizeValue !important;
    }

    .#{$targetIndex}x-#{$sizeIndex} {
      #{$targetValue}-left: $sizeValue !important;
      #{$targetValue}-right: $sizeValue !important;
    }

    .#{$targetIndex}y-#{$sizeIndex} {
      #{$targetValue}-top: $sizeValue !important;
      #{$targetValue}-bottom: $sizeValue !important;
    }
  }
}
