// MAIN COLORS
$CBlack: #000;
$CBlack40: rgba($CBlack, 0.4);
$CAcsent: rgba(7, 179, 232, 1);
$CWhite: #fff;
$CWhite10: rgba($CWhite, 0.1);
$CWhite20: rgba($CWhite, 0.2);
$CWhite40: rgba($CWhite, 0.4);
$CWhite80: rgba($CWhite, 0.8);
$CTransparent: rgba(0, 0, 0, 0);
$CTransparentL: rgba(0, 0, 0, 0.1);
$CGradient: linear-gradient(136.66deg, #00A5EE 1.58%, #14D7D1 96.24%);

// // OLD COLORS
$CWhiteLight: #edf2f7;
$CDark: #010220;
$CDark30: rgba(1, 2, 32, 0.3);
$CBlue: #0d93e2;
$CBlue700: #4DC0F3;
$CPurple: #0d93e2;
$CGreen: #1ecd79;
$COrange: #ff8c39;
$CBlueLight: #5ac8fa;
$CRed: #ff4439;

$CLightGrey: #a0aec0;
$CGrey100: #F2F2F7;
$CGrey150: #E5E5EA;
$CGrey200: #D1D1D6;
$CGrey250: #F6F6F6;
$CGrey300: #C7C7CC;
$CGrey400: #AEAEB2;
$CGrey500: #8E8E93;
$CGrey600: #636366;
$CGrey700: #48484A;
$CGrey800: #3A3A3C;
$CGrey900: #1C1C1E;
$CGrey1000: #404040;

$FWB: 700;
$FWSB: 600;
$FWM: 500;
$FWN: 400;
$FWL: 300;

// FONTS
$FFamilyMain: 'Roboto';
$FFamilySecond: 'Roboto';

$FFTitle: $FFamilyMain;
$FFText: $FFamilyMain;

$FSTitleB: 30px;
$FLHTitleB: 45px;

$FSTitleN: 24px;
$FLHTitleN: 36px;

$FSTitleS: 20px;
$FLHTitleS: 30px;

$FSTextB: 17px;
$FLHTextB: 25.5px;

$FSTextBCaps: 16px;
$FLHTextBCaps: 24px;

$FSTextN: 15px;
$FLHTextN: 22.5px;

$FSTextNCaps: 14px;
$FLHTextNCaps: 21px;

$FSTextS: 13px;
$FLHTextS: 19.5px;

$FSTextSCaps: 12px;
$FLHTextSCaps: 18px;

$FSizeH1: 30px;
$FLHH1: 45px;

$FSizeH2: 38px;
$FLHH2: 53.2px;

$FSizeH3: 30px;
$FLHH3: 42px;

$FSizeH4: 24px;
$FLHH4: 33.6px;

$FSizeH5: 18px;
$FLHH5: 28.8px;

$FSizeTB: $FSizeH5;
$FLHTB: $FLHH5;

$FSizeTN: 16px;
$FLHTN: 24px;

$FSizeTS: 14px;
$FLHTS: 21px;

$FSizeTXS: 12px;
$FLHTXS: 16.8px;

$FSpacingMain: 0.1px;
$FSpacingNone: 0;

// media
$media-big: 1440px;
$media-dp: 1282px;
$media-dpsm: 1282px;

$mediaBigScreen: 'min-width: 1441px';
$mediaDesktop: 'min-width: 1282px';
$mediaDesktopSmall: 'max-width: 1281px';
$mediaTabletBig: 'max-width: 992px';
$mediaTablet: 'max-width: 768px';
$mediaMobile: 'max-width: 600px';
$mediaMobileSmall: 'max-width: 480px';
$mediaPortrait: 'orientation: portrait';
$mediaRetina: 'min-resolution: 2dppx';
