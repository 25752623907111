ymaps[class$="-controls__control"] {
  [class$="-zoom"] {
    width: 40px;
    padding: 39px 0;
  }

  [class*="-zoom__button"] {
    position: relative;
    height: 40px;
    padding-left: 38px;
    background-color: #666 !important;

    [class*="-zoom__icon"] {
      background-image: unset;
    }
  }

  [class*="-zoom__button"][class$="-_disabled"] {
    &::before,
    &::after {
      background-color: #999;
    }
  }

  [class*="-zoom__minus"],
  [class*="-zoom__plus"] {
    &::before,
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      width: 20px;
      height: 2px;
      background-color: #fff;
    }

    &::before {
      transform: translate(-50%, -50%);
      content: '';
    }
  }

  [class*="-zoom__plus"] {
    &::after {
      transform: translate(-50%, -50%) rotate(-90deg);
      content: '';
    }
  }
}
